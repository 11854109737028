import React from 'react';
import cx from 'classnames';
import * as styles from "./style.module.css";
import {NanoIcon} from "@nanoporetech-digital/components-react";
import {isTransactional} from "../../../hooks/siteData";
import {getFullUrl} from "../../Layout/MegaDropDown/InternalLink/internalLink";

export default function PriceListLink() {
    if (!isTransactional()) {
        return null;
    }

    const linkStyle = cx(styles.navLink, styles.section);
    const url = getFullUrl('/priceList.html');

    return (
        <a href={url} className={linkStyle} target="_blank">
            <div className={styles.contents}>
                <div>Price List</div>
                <NanoIcon name="light/external-link" />
            </div>
        </a>
    )
}
