import React from 'react';

import { useCustomerContext } from "../../../contexts/CustomerContext";
import { getDistributorStoreUrl } from '../../../hooks/useEnvironment';

export default function AdminOrderHeader() {
    if (typeof window==='undefined') return null;

    const { customer } = useCustomerContext();

    const isAdminMode = customer.is_admin_mode;
    const adminData = customer.admin_mode;

    if (!(isAdminMode && adminData)) {
        return null;
    }

    const distributorsUrl = getDistributorStoreUrl();
    const urlSuffix = `adminAuthenticate/?code=${adminData.admin_token}`;

    return (
        <div className="info-banner info-banner--warning" style={{marginBottom: '0px'}}>
            <div className="info-banner__content">
                <span className="info-banner__label">You are currently in admin mode</span>
                <span className="info-banner__heading">Invoice account <code>{adminData.initial_customer_email}</code><br />End user account <code>{customer.email}</code></span>
                <p className="info-banner__text">Your CS user id <code>{adminData.csc_username}</code> will be logged against any orders placed</p>
                <p style={{color:"red"}}>&#9888; Does this order need to be placed in the <a href={`${distributorsUrl}${urlSuffix}`}>Distributors</a> or <a href={`${distributorsUrl}jp/${urlSuffix}`}>JP Dealers</a> store?</p>
            </div>
        </div>
    );
}
