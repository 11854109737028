import React from 'react';
import { getUrlPathForCurrentStore } from '../../../../hooks/siteData';

export default function InternalLink(props) {
    const url = getFullUrl(props.to);
    const go = () => window.location.href = url;

    return (
        <>
            <a onClick={go}>{props.children}</a>
            <style jsx>
            {`
                a:hover {
                    cursor: pointer;
                }
            `}
            </style>
        </>
    );
}

export function getFullUrl(resource) {
    if (typeof window === 'undefined') return resource;

    const useResource = (resource.indexOf('/') === 0) ? resource.substring(1) : resource;
    const url = new URL(window.location.href);
    const path = getUrlPathForCurrentStore();
    return `${url.origin}${path}${useResource}`;
}
