import React, {useRef, useState} from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import { useCartContext } from '../../../../contexts/CartContext';
import SectionHeader from '../sectionHeader';
import SectionSubmit from '../sectionSubmit';
import { gql, useMutation } from '@apollo/client';
import { navigate } from "gatsby"
import {getFilePayload, getOrderInput} from '../helpers';
import ReviewSummary from './reviewSummary';
import * as styles from '../style.module.css';
import AdditionalUploads from './additionalUploads';
import {MyAccountForm} from './myAccountForm';
import {orderFields, payerAuthenticationFields} from "../../../../fragments/live/order";

export const CODE = 'review';
export const TITLE = 'Order review';

export default function OrderReview({step, cartData}) {
    const [checkout] = useCheckoutContext();
    const { cart, resetCart } = useCartContext();
    const [placeOrderMutation] = getPlaceOrderMutation();
    const [uploadPoMutation] = getUploadPurchaseOrderMutation();
    const isActive = checkout.activeStep===step;
    const section = checkout[CODE] ?? {};
    const myAccountFormRef = useRef(null);

    // local state to avoid global re-render on small changes - needed for additional uploads
    const [cache, setCache] = useState({
        additional_uploads: [],
    });

    const [orderNumber, setOrderNumber] = useState();

    const method = checkout.payment_method?.selected_payment_method;
    const isCybersourcePayment = method === 'chcybersource';
    const isMyAccountPayment = method === 'ont_my_account';
    const isPurchaseOrderPayment = method === 'purchaseorder';

    async function submit() {
        if (isPurchaseOrderPayment) {
            await submitPO(cart.id, checkout, uploadPoMutation); // error handled by SectionSubmit
        }

        const additionalUploads = await Promise.all(cache.additional_uploads.map(file => getFilePayload(file)));

        const orderInput = getOrderInput(cart, checkout, additionalUploads);
        const { data } = await placeOrderMutation({
            variables: {
                orderData: orderInput
            }
        });

        if (isCybersourcePayment && data) {
            await submit3dSecure(data);
        }

        if (data?.placeOrder?.order?.order_number) {
            resetCart();
            setOrderNumber(data?.placeOrder?.order?.order_number)

            if (isMyAccountPayment) {
                myAccountFormRef.current.submit();
            }
            else {
                navigate('/checkout/success', {state: {order: data.placeOrder.order}});
            }
        } else {
            console.log("Should I be here?");
        }
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent settings={{cache, setCache, section}} cartData={cartData} />}
            {isActive && <SectionSubmit callback={submit} />}
            {isActive && isMyAccountPayment && <MyAccountForm
                orderNumber={orderNumber}
                cartData={cartData}
                formRef={myAccountFormRef}
            />}
        </div>
    );
}

function SectionContent({settings, cartData}) {
    const {cache, setCache} = settings; // we'll need this for additional uploads

    return(
        <div className={styles.sectionContent}>
            <ReviewSummary cartData={cartData} />
            <AdditionalUploads cache={cache} setCache={setCache} />
        </div>
    );
}

async function submit3dSecure(placeOrderFirstCallResult) {
    if (placeOrderFirstCallResult.placeOrder?.payer_authentication?.parameters) {
        const params = JSON.parse(placeOrderFirstCallResult.placeOrder.payer_authentication.parameters);

        // see https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/557065/Songbird.js#Songbird.js-Cardinal.continue
        await Cardinal.continue('cca',
            {
                "AcsUrl": params.cca.AcsUrl,
                "Payload": params.cca.Payload
            },
            {
                "OrderDetails": params.order.OrderDetails
            }
        )
    }
}

async function submitPO(cartId, checkout, uploadPoMutation) {
    const poFile = await getFilePayload(checkout.payment_details.po_file);
    const payload = {
        cartId: cartId,
        purchaseOrderNumber: checkout.payment_details.purchase_order_number,
        poFile: poFile,
        additionalInformation: checkout.payment_details.additional_information ?? null,
    }
    await uploadPoMutation({variables: payload});
}

function getPlaceOrderMutation() {
    return useMutation(gql`
        mutation PlaceOrder($orderData: PlaceOrderInput) {
            placeOrder(
                input: $orderData
            ) {
                order {
                    ...orderFields
                }

                payer_authentication {
                    ...payerAuthenticationFields
                }
            }
        }

        ${orderFields}
        ${payerAuthenticationFields}
    `);
}

function getUploadPurchaseOrderMutation() {
    return useMutation(gql`
        mutation UploadPurchaseOrder(
            $cartId: String!,
            $purchaseOrderNumber: String!,
            $poFile: NanoporeFile!,
            $additionalInformation: OntPaymentAdditionalInformationInput
        ) {
            uploadPurchaseOrder(
                input: {
                    cart_id: $cartId
                    purchase_order_number: $purchaseOrderNumber
                    po_file: $poFile
                    additional_information: $additionalInformation
                }
            ) {
                success
            }
        }
    `);
}
