import countryIsoToUrlPath from "./webStore/countryIsoToUrlPath.json";
import STORE_TO_COUNTRY from "./webStore/storeViewCodesToCountryIso.json";
import countryRules from "./webStore/countryRules.json";
import {getUrlPath} from "../hooks/siteData";

const REDIRECT_EXCLUDE_PATHS = [
    "/maintenance",
    "/countrySelect",
    "/authenticate",
    "/reauthenticate",
    "/quote/retrieve",
    "/checkout/success"
];

export const getStoreFromCountryIso = countryIso => {
    for (const [store, countries] of Object.entries(STORE_TO_COUNTRY)) {
        if (countries.includes(countryIso)) {
            return store;
        }
    }

    return "default";
}

export const isDistributorOnly = countryIso => {
    return countryRules.distributorOnly.includes(countryIso);
}

export const isDistributorOptional = countryIso => {
    return countryRules.distributorOptional.includes(countryIso);
}

export const isDistributorCountry = countryIso => {
    return isDistributorOnly(countryIso) || isDistributorOptional(countryIso);
}

export const getDistributorTab = countryIso => {
    return countryRules.distributorTabs[countryIso] ?? '';
}

export const isDisableGeoip = () => {
    return (process.env.GATSBY_DISABLE_GEOIP=='true' || process.env.GATSBY_DISABLE_GEOIP==1);
}

export const isExemptPage = () => {
    const {pathname} = new URL(window.location.href);
    for (const path of REDIRECT_EXCLUDE_PATHS) {
        if (pathname.includes(path)) return true;
    }
    return false;
}

export const redirectIfRequired = store => {
    if (!store || isExemptPage()) {
        return;
    }

    const target = getUrlFromStoreViewCode(store);
    if (window.location.href === target) {
        return null;
    }

    console.log("designated store", store)
    console.log("current svc", process.env.GATSBY_STORE_VIEW_CODE)

    if (store && store !== process.env.GATSBY_STORE_VIEW_CODE) {
        window.location.replace(target)
    }
}

const getUrlFromStoreViewCode = code => {
    // e.g. origin: https://store.nanoporetech.com, pathname: /eu/hello.html, search: ?foo=1
    const {origin, pathname, search} = new URL(window.location.href);
    let usePath = pathname;
    if (pathname.startsWith('/eu') || pathname.startsWith('/us') || pathname.startsWith('/uk') || pathname.startsWith('/jp') || pathname.startsWith('/cn')) {
        usePath = pathname.substr(3);
    }
    return origin + getUrlPath(code) + usePath.substring(1) + search;
}
