import React, {useMemo} from 'react';
import {NanoGrid, NanoIcon, NanoNavItem} from "@nanoporetech-digital/components-react";
import * as styles from "../style.module.css";
import useSiteData from "../../../../hooks/siteData";

export default function TopLevelMenuItem({menuData, title, cssColumnCount = 1, cssColSpan = 1, displayBlockSections = []}) {
    const cwsUrl = getCwsUrl();

    const menuEntry = useMemo(() => getMenuByTitle(menuData, title), [menuData, title]);
    if (!menuEntry) return null;

    const featuredSection = menuEntry.items.find(section => section.featured===true);

    return (
        <NanoNavItem slot="main">
            <span className={styles.topLink}>{menuEntry.title}</span>
            <div slot="secondary" className={styles['mdd' + menuEntry.title]}>
                <div className={styles.content}>
                    <div className={styles.megadd}>
                        <div className={styles.wrapper}>
                            <NanoGrid className={styles.grid} s-cols="1" l-cols={cssColumnCount}>
                                {menuEntry.items.map((item, i) =>
                                    <MenuSection
                                        section={item}
                                        cwsUrl={cwsUrl}
                                        cssColSpan={cssColSpan}
                                        displayBlockSections={displayBlockSections}
                                        key={i}
                                    />
                                )}
                            </NanoGrid>
                        </div>
                        <Featured section={featuredSection} cwsUrl={cwsUrl} />
                    </div>
                </div>
                <div className={styles.burgerMenu + ' ' + styles.burgerContent}>
                    <button
                        className={styles.backBtn}
                        onClick={e => {e.target.closest('nano-nav-item').open = false}}
                    >
                        <NanoIcon name="solid/chevron-left" slot="icon-end" />&nbsp;{menuEntry.title}
                    </button>
                    {menuEntry.items.map((item, i) => <MobileMenuSection section={item} cwsUrl={cwsUrl} key={i} />)}
                </div>
            </div>
        </NanoNavItem>
    );
}

function Featured({section, cwsUrl}) {
    if (!section) return null;
console.log('section', section);
    return (
        <div className={styles.featured}>
            <h2>{section.title}</h2>
            <NanoGrid s-cols="2" l-cols="2">
                <p>{section.description}</p>
                <ul>
                    {section.items?.map((item, i) => <li key={i}><MenuLink data={item} cwsUrl={cwsUrl} /></li>)}
                </ul>
            </NanoGrid>
        </div>
    );
}

function MenuSection({section, cwsUrl, cssColSpan, displayBlockSections}) {
    const gridStates = `s-col-span-1, l-col-span-${cssColSpan}`;

    if (section.featured) return null;

    const ulStyle = displayBlockSections.includes(section.title)
        ? {display: 'block'}
        : {};

    return <div grid-states={gridStates}>
        <h2>{section.title}</h2>
        <div className={styles.links}>
            <ul style={ulStyle}>
                {section.items?.map((item, i) => <li key={i}><MenuLink data={item} cwsUrl={cwsUrl} /></li>)}
            </ul>
        </div>
    </div>
}

function MobileMenuSection({section, cwsUrl}) {
    return <>
        <h3>{section.title}</h3>
        {section.items?.map((item, i) =>
            <NanoNavItem key={i}>
                <MenuLink data={item} cwsUrl={cwsUrl}/>
            </NanoNavItem>
        )}
    </>;
}

function MenuLink({data, cwsUrl}) {
    return <a href={cwsUrl + data.link.url}>
        {data.label}
        {data.sub_text && <small>{data.sub_text}</small>}
    </a>
}

function getCwsUrl() {
    const { home } = useSiteData();
    return home.startsWith('https') ? home : 'https://nanoporetech.com';
}

function getMenuByTitle(data, title) {
    return data.find((item) => {
        return item.component === 'menu' && item.title === title
    });
}
