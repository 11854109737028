// extracted by mini-css-extract-plugin
export var backBtn = "style-module--backBtn--cf2bb";
export var burgerContent = "style-module--burgerContent--df326";
export var burgerMenu = "style-module--burgerMenu--491eb";
export var content = "style-module--content--2388a";
export var featured = "style-module--featured--53d54";
export var grid = "style-module--grid--b8fc2";
export var links = "style-module--links--55b56";
export var mddApplications = "style-module--mddApplications--c8e78";
export var megadd = "style-module--megadd--538b0";
export var topLink = "style-module--topLink--4fdd3";
export var wrapper = "style-module--wrapper--7b5a2";