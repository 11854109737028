import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import useSiteData, {isChinaStore} from '../../../hooks/siteData';
import {NanoNavItem} from '@nanoporetech-digital/components-react';
import PromotionItems from './PromotionItems/promotionItems';
import * as styles from './style.module.css';
import TopLevelMenuItem from "./TopLevelMenuItem";

export default function MegaDropDown() {
    const data = useStaticQuery(graphql`
query globalNav {
  customApi {
    story {
      content {
        global_nav {
          promo_cards {
              component
              title
              link_label
              body
              link {
                url
              }
          }

          menu_primary {
            component
            title
            items {
              title
              featured
              description
              items {
                label
                sub_text
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
  `);

    const globalNav = data.customApi.story.content.global_nav[0];

    const menuData = globalNav.menu_primary;
    const promoCards = globalNav.promo_cards ?? null;

    return (
        <>
            <OverflowSlot />
            <MainSlot menuData={menuData} />
            <PromotionSlot promoCards={promoCards} />
        </>
    );
}

function OverflowSlot() {
    return null;

    return (
        <NanoNavItem slot="overflow">
            <a onClick={() => {}}>Frequently asked questions</a>
        </NanoNavItem>
    );
}

function MainSlot({menuData}) {
    const cwsUrl = getCwsUrl();

    return (
        <>
            <Products menuData={menuData} />
            <Applications menuData={menuData} />
            <Store />
            <Resources cwsUrl={cwsUrl} />
            <Support cwsUrl={cwsUrl} />
            <About menuData={menuData} />
        </>
    );
}

function PromotionSlot({promoCards}) {
    if (isChinaStore()) return null;

    return (
        <NanoNavItem slot="promotion">
            <PromotionItems promoCards={promoCards}/>
        </NanoNavItem>
    );
}

function Products({menuData}) {
    return <TopLevelMenuItem
                title={'Products'}
                cssColumnCount={2}
                menuData={menuData}
                displayBlockSections={['Discover nanopore sequencing']}
            />;
}

function Applications({menuData}) {
    return <TopLevelMenuItem
        title={'Applications'}
        cssColumnCount={4}
        cssColSpan={2}
        displayBlockSections={[]}
        menuData={menuData}
    />
}

function Store() {
    return (
        <NanoNavItem slot="main" selected={true}>
            <span><Link className={styles.topLink} to="/">Store</Link></span>
        </NanoNavItem>
    );
}

function Resources({cwsUrl}) {
    return (
        <NanoNavItem slot="main">
            <span><a className={styles.topLink}  href={`${cwsUrl}/resource-centre`}>Resources</a></span>
        </NanoNavItem>
    );
}

function Support({cwsUrl}) {
    return (
        <NanoNavItem slot="main">
            <span><a className={styles.topLink}  href={`${cwsUrl}/support`}>Support</a></span>
        </NanoNavItem>
    );
}


function About({menuData}) {
    return <TopLevelMenuItem
        title={'About'}
        cssColumnCount={7}
        cssColSpan={2}
        displayBlockSections={['Global partners', 'News & Events']}
        menuData={menuData}
    />;
}

function getCwsUrl() {
    const { home } = useSiteData();
    return home.startsWith('https') ? home : 'https://nanoporetech.com';
}
