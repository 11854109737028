import React, {useRef, useState} from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import { useCartContext } from '../../../../contexts/CartContext';
import SectionHeader from '../sectionHeader';
import SectionSubmit from '../sectionSubmit';
import { gql, useMutation } from '@apollo/client';
import { navigate } from "gatsby"
import {getFilePayload, getOrderInput, getRedeemQuotationInput} from '../helpers';
import ReviewSummary from './reviewSummary';
import AdditionalUploads from './additionalUploads';
import * as styles from '../style.module.css';
import {MyAccountForm} from "./myAccountForm";
import {orderFields, payerAuthenticationFields, redeemQuotationFields} from "../../../../fragments/live/order";

export const CODE = 'review';
export const TITLE = 'Order review';

export default function QuoteReview({step, cartData}) {
    const [checkout] = useCheckoutContext();
    const { cart, resetCart } = useCartContext();
    const [placeOrderMutation] = getRedeemQuotationMutation();
    // const [ccPlaceOrderMutation] = getRedeemQuotationWithCcMutation();
    const isActive = checkout.activeStep===step;
    const section = checkout[CODE] ?? {};
    const myAccountFormRef = useRef(null);

    const method = checkout.payment_method?.selected_payment_method;
    const isPurchaseOrder = method === 'purchaseorder';
    const isMyAccountPayment = method === 'ont_my_account';

    // local state to avoid global re-render on small changes - needed for additional uploads
    const [cache, setCache] = useState({
        additional_uploads: [],
    });

    const [orderNumber, setOrderNumber] = useState();

    async function submit() {
        const additionalUploads = await Promise.all(cache.additional_uploads.map(file => getFilePayload(file)));

        const basicPayload = getRedeemQuotationInput(cart, checkout, additionalUploads);

        let variables = {};

        if (isPurchaseOrder) {
            const poFile = await getFilePayload(checkout.payment_details.po_file);
            variables = {
                quotationData: {
                    ...basicPayload,
                    purchase_order_number: checkout.payment_details.purchase_order_number,
                    po_file: poFile,
                    payment_method: "purchaseorder",
                    payment_additional_information: checkout.payment_details.additional_information ?? null,
                }
            }
        } else {
            variables = {
                quotationData: {
                    ...basicPayload,
                    payment_method: method,
                }
            }
        }

        const { data } = await placeOrderMutation({
            variables: variables
        }); // error handled by SectionSubmit
        const placeOrderResult = data.redeemQuotation;

        if (method==='chcybersource' && placeOrderResult) {
            await submit3dSecure(placeOrderResult);
        }

        if (placeOrderResult?.order?.order_number) {
            setOrderNumber(placeOrderResult?.order?.order_number);
            resetCart();
            if (isMyAccountPayment) {
                myAccountFormRef.current.submit();
            } else {
                navigate('/checkout/success', {state: {order: placeOrderResult.order}});
            }
        }
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent settings={{cache, setCache, section}} cartData={cartData} />}
            {isActive && <SectionSubmit callback={submit} />}
            {isActive && isMyAccountPayment && <MyAccountForm
                orderNumber={orderNumber}
                cartData={cartData}
                formRef={myAccountFormRef}
            />}
        </div>
    );
}

function SectionContent({settings, cartData}) {
    const {cache, setCache} = settings; // we'll need this for additional uploads

    return(
        <div className={styles.sectionContent}>
            <ReviewSummary cartData={cartData} />
            <AdditionalUploads cache={cache} setCache={setCache} />
        </div>
    );
}

async function submit3dSecure(placeOrderFirstCallResult) {
    if (placeOrderFirstCallResult.payer_authentication?.parameters) {
        const params = JSON.parse(placeOrderFirstCallResult.payer_authentication.parameters);

        // see https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/557065/Songbird.js#Songbird.js-Cardinal.continue
        await Cardinal.continue('cca',
            {
                "AcsUrl": params.cca.AcsUrl,
                "Payload": params.cca.Payload
            },
            {
                "OrderDetails": params.order.OrderDetails
            }
        )
    }
}

function getRedeemQuotationMutation() {
    return useMutation(gql`
        mutation RedeemQuotation($quotationData: RedeemQuotationInput!) {
            redeemQuotation(
                input: $quotationData
            ) {
                order {
                    ...redeemQuotationFields
                }

                payer_authentication {
                    ...payerAuthenticationFields
                }
            }
        }
        ${redeemQuotationFields}
        ${payerAuthenticationFields}
    `);
}
